<template>
  <b-container
    class=""
    style="opacity:0;position:absolute;z-index:-1;top:-100%;"
  >
    <b-row
      id="print-me"
      class="justify-content-center pt-2"
      style="background-color: #f6f7fa;"
    >
      <b-col
        id="contentPrintA5Delivery"
        ref="contentPrintA5Delivery"
        cols="12"
        lg="12"
      >
        <b-container class="h-100 bg-white" style="letter-spacing: 1px;">
          <div class="pt-5 px-lg-3 px-2 pb-3">

            <b-img v-if="result.branch && result.branch.photo_url.includes('png') || result.branch.photo_url.includes('jpg')" :src="result.branch.photo_url" alt="logo" style="width: 100px;" />
            
            <h6 class="size16 text-black mb-0 mt-2">
              Surat Jalan
            </h6>
            <b-row class="mt-2">
              <b-col
                cols="6"
                md="6"
                lg="6"
              >
                <h6 class="size10 fw-bold-500 text-black mb-1" v-if="result.branch">
                  {{ result.branch.name || '-' }}
                </h6>
                <h6 class="size10 fw-bold-500 text-black mb-1" v-if="result.branch">
                  {{ result.branch.address || '-' }}, No. telepon {{ result.branch.phone }}
                </h6>
                <h6 class="size16 text-black">
                  No. Invoice: {{ result.invoice_number || '-' }}
                </h6>
                <h6
                  v-if="result.po_so_number"
                  class="size10 fw-bold-500 text-black mb-1"
                >
                  No. PO SO: {{ result.po_so_number || '-' }}
                </h6>
                <h6 class="size16 text-black mt-5">
                  Tanggal: {{ result.date || '-' }}
                </h6>
              </b-col>
              <b-col
                cols="6"
                md="6"
                lg="6"
                class="pl-lg-5"
              >
                <div class="mb-lg-2">
                  <h6 class="size10 fw-bold-500 text-black mb-1">
                    Sales: {{ result.seller && result.seller.name || '-' }}
                  </h6>
                  <h6
                    v-if="result.customer"
                    class="size10 fw-bold-500 text-black"
                  >
                    Pelanggan: {{ result.customer.name }}
                  </h6>
                  <h6
                    v-if="deliveryNumbers.length"
                    class="size10 fw-bold-500 text-black mb-1"
                  >
                    No. Surat Jalan: {{ deliveryNumbers.join(', ') || '-' }}
                  </h6>
                </div>
                <div
                  v-if="result.receiver_name"
                  class="d-flex"
                >
                  <h6 class="size10 fw-bold-600 text-black">
                    Penerima : &nbsp;
                  </h6>
                  <h6 class="size10 fw-bold-500 text-black ml-1">
                    {{ result.receiver_name || '-' }}
                  </h6>
                </div>
                <div
                  v-if="result.receiver_address"
                  class="d-flex"
                >
                  <h6 class="size10 fw-bold-600 text-black opacity-0" style="opacity: 0.5; text-fill-color: transparent;">
                    Penerima : &nbsp;
                  </h6>
                  <!-- <h6
                    class="size10 fw-bold-500 text-black ml-3"
                    v-html="sanitizeHTML(result.receiver_address)"
                  /> -->
                  <h6
                    class="size10 fw-bold-500 text-black ml-3"
                  >
                  {{ result.receiver_address }}
                  </h6>
                </div>
                <div
                  v-if="result.receiver_phone_number"
                  class="d-flex"
                >
                  <h6 class="size10 fw-bold-600 text-black">
                    Telp : &nbsp;
                  </h6>
                  <h6 class="size10 fw-bold-500 text-black ml-1">
                    {{ result.receiver_phone_number || '-' }}
                  </h6>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-1 mb-3">
              <b-col lg="12">
                <table class="table table-hover">
                  <thead>
                    <tr class="border-bottom">
                      <th
                        scope="col"
                        class="text-left"
                      >
                        No
                      </th>
                      <th
                        scope="col"
                        class="text-left"
                      >
                        Item
                      </th>
                      <th
                        scope="col"
                        class="text-left"
                      >
                        Qty
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in deliveries"
                      :key="index"
                    >
                      <td class="border-0">
                        {{ index + 1 }}
                      </td>
                      <td class="border-0">
                        {{ item.name }}
                      </td>
                      <td class="border-0">
                        {{ item.qty.toString().replace('.', ',') }} {{ item.unit }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr>
                <h6 class="text-black">
                  Memo
                </h6>
                <div class="mb-2" v-html="memo" />   
              </b-col>
            </b-row>
            <b-row align-h="end">
              <b-col cols="6" class="mb-5">
                <h6 class="text-black size16">
                  TTD Penerima
                </h6>
              </b-col>
              <b-col cols="6" class="mb-5">
                <h6 class="text-black size16 ml-5 text-center">
                  Hormat Kami
                </h6>
              </b-col>
              <b-col lg="6 mt-5">
                <h6
                  class="text-black size16 mb-0"
                >
                  Note:
                </h6>
                <h6
                  class="text-black size16 mb-0 font-italic"
                >
                  *Harga barang sudah termasuk PPn 11%
                </h6>
                <h6
                  class="text-black size16 mb-0 font-italic"
                >
                  *Barang yang sudah dibeli tidak dapat dikembalikan
                </h6>
                <h6
                  v-if="result.returs_id && result.returs_id.length > 0"
                  class="size16 text-black mt-4 mb-0"
                >
                  {{ result.returs_id.join(', ') }}
                </h6>
              </b-col>
            </b-row>
          </div>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer, BRow, BCol, BImg
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BContainer,
    BImg
  },
  props: {
    /* eslint-disable vue/require-default-prop */
    result: {
      type: Object,
    },
    merchant: {
      type: Object,
    },
    dataDelivery: {
      type: Array,
    },
    deliveryNumbers: {
      type: Array,
    },
    memo: {
      type: String,
    },
  },
  data() {
    return {
      moment,
      deliveries: [],
    }
  },
  watch: {
    dataDelivery: {
      handler(value) {
        this.deliveries = value
      },
      deep: true,
    },
  },
  methods: {
    sanitizeHTML(html) {
      return html?.replace(/<\/?p>/g, '');
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables.scss';

.opacity-0 {
  opacity: 0 !important;
}
.table thead tr th {
  font-weight: 800;
  color: #2B324F;
  font-size: 16px;
}
.table tbody tr td {
  font-weight: 500;
  color: #2B324F;
  font-size: 16px;
}
.bg-wave {
  background-image: url('../assets/images/wave-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

button {
  &.bg-white {
    background-color: #FFFFFF !important;
    border: 1px solid $light--3 !important;
    color: $dark !important;
  }
}

label {
  font-size: 14px;
  color: $dark;
  font-weight: 600;
}

.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}

.custom__input {
  height: 52px;
  background: #EFF1F5;
  border: 1px solid #E4E5EC;
  border-radius: 8px;
}

.vs__dropdown-toggle {
  height: 52px;
  background-color: #EFF1F5;
  border: 1px solid #E4E5EC;
  border-radius: 8px;
}

// #contentPrintA5 {
//     page-break-before: always;
// }
</style>
